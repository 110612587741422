import { Contact } from 'ProvidersInformation-Types';
import { getTranslation } from '@travel/translation';

export const TYPE = {
  PHONE_PROVIDERINFO: 'PHONE_PROVIDERINFO',
  PHONE_RESERVATION: 'PHONE_RESERVATION',
  FAX: 'FAX_PROVIDERINFO',
  EMAIL: 'EMAIL_PROVIDERINFO',
};

export const contactTypeOptions = [
  {
    text: '-',
    value: '',
  },
  {
    text: getTranslation({ id: 'Supplier_Enumeration.Contact_Method.Phone_ProviderInfo' }),
    value: TYPE.PHONE_PROVIDERINFO,
  },
  {
    text: getTranslation({ id: 'Supplier_Enumeration.Contact_Method.Phone_Reservation' }),
    value: TYPE.PHONE_RESERVATION,
  },
  {
    text: getTranslation({ id: 'Supplier_Enumeration.Contact_Method.FAX_ProviderInfo' }),
    value: TYPE.FAX,
  },
  {
    text: getTranslation({ id: 'Supplier_Enumeration.Contact_Method.Email_ProviderInfo' }),
    value: TYPE.EMAIL,
  },
];
/**  filter in case all prop is empty. then map data. */
export const trimContacts = (contacts: Contact[]) => {
  /* Removed from and to temporary [TEQB-5982] */
  return contacts
    .filter(contact => contact.type || contact.content /* || contact.from || contact.to */)
    .map(contact => ({
      type: contact.type || '',
      content: contact.content || '',
      // from: contact.from || undefined,
      //to: contact.to || undefined,
    }));
};

export const isDefaultContactType = (type: string) => {
  return (
    type === TYPE.PHONE_PROVIDERINFO ||
    type === TYPE.PHONE_RESERVATION ||
    type === TYPE.FAX ||
    type === TYPE.EMAIL
  );
};
