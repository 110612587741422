// Language Multiplier
const LANGUAGE_MULTIPLIER: { [key: string]: number } = {
  'ja-jp': 1, // Japanese
  'en-us': 2.5, // English
  'ko-kr': 1.2, // Korean
  'zh-tw': 0.8, // Traditional Chinese (Taiwan)
  'zh-hk': 0.8, // Traditional Chinese (HongKong)
  'zh-cn': 0.8, // Simplified Chinese
  'th-th': 2.2, // Thai
  'id-id': 2.6, // Bahasa Indonesian
  'de-de': 2.7, // German
  'it-it': 2.7, // Italian
  'vi-vn': 3.5, // Vietnamese
};

// DB Max
const MAX_MULTIPLIER = 3.5;

// value for rounding up the calculated number of character to the nearest ten.
const BASE = 10;

export function getMaxLength(length: number, inputLanguage: string) {
  const multiplier =
    LANGUAGE_MULTIPLIER[(inputLanguage || 'en-US').toLowerCase()] || MAX_MULTIPLIER;
  return Math.ceil(parseFloat((((length / MAX_MULTIPLIER) * multiplier) / BASE).toFixed(2))) * BASE;
}
